export default function passwordChecker() {
  const passwordCheckerWrap = 'js-password-checker';
  const passwordCheckerList = document.body.querySelectorAll(`.${passwordCheckerWrap}`);

  const passwordCheckerRule = 'js-password-checker-rule';
  const passwordCheckerRuleIsActive = 'o-password-checker__rule--active';
  const passwordCheckerTriggerEvent = 'keyup';

  function initListeners(wrap) {
    const targetID = wrap.dataset.targetId;
    const targerInput = document.getElementById(targetID);
    if (targerInput) {
      targerInput.addEventListener(
        passwordCheckerTriggerEvent,
        (e) => {
          const value = e.target.value;
          const rules = wrap.querySelectorAll(`.${passwordCheckerRule}`);
          rules.forEach((rule) => {
            const ruleRegExp = new RegExp(rule.dataset.regExp);
            if (value.match(ruleRegExp)) {
              rule.classList.add(passwordCheckerRuleIsActive);
            } else {
              rule.classList.remove(passwordCheckerRuleIsActive);
            }
          });
        }
      );
    }
  }

  function passwordCheckerInit() {
    passwordCheckerList.forEach((checker) => initListeners(checker));
  }

  passwordCheckerInit();
}
