import { trimWhiteSpaces } from '../utils';

export const GA_CSS_CLASS_MENU_OPENED = 'js-ga-menu-is-opened';
export const GA_CSS_CLASS_MENU_CLOSED = 'js-ga-menu-is-closed';

const GOOGLE_ANALYTICS_DATA_ATTR = '[data-ga-data]';

/*
 @description:
 "view" property is dedicated for events triggered onScroll to specific element,
 "exit" property is dedicated for events triggered on anchor that targeting to external sites,
 "click" property is dedicated for the rest of the events,
 */
const GA_ACTION_TYPES = {
  exit: 'exit',
  click: 'click',
  view: 'view',
};

/*
 @description:
 "open" property is dedicated for the event related to opening of Hamburger Menu,
 "close" property is dedicated for the event related to closing of Hamburger Menu.
 */
const GA_HAMBURGER_MENU_NAMES_TYPES = {
  open: 'open',
  close: 'close'
};

const GA_SEARCHED_ATTR_ACTION = 'action';
const GA_SEARCHED_ATTR_NAME = 'name';

const getParsedJsonData = (data = null) => (data ? JSON.parse(data) : null);

const isHamburgerMenuOpened = (clickEventData) => clickEventData?.currentTarget?.classList?.contains(GA_CSS_CLASS_MENU_OPENED);

const isHamburgerMenuClosed = (clickEventData) => clickEventData?.currentTarget?.classList?.contains(GA_CSS_CLASS_MENU_CLOSED);

/* @description: "Setter" that mutating Google Analytic Data Layer object before push */
const setGoogleAnalyticsActionAttr = (gaActionValue) => {
  let gaActionAttr = '';
  if (trimWhiteSpaces(gaActionValue.toLowerCase()) === GA_ACTION_TYPES.exit) {
    gaActionAttr = GA_ACTION_TYPES.exit;
  } else {
    gaActionAttr = GA_ACTION_TYPES.click;
  }
  return gaActionAttr;
};

/* @description: "Setter" that mutating Google Analytic Data Layer object before push */
const setGoogleAnalyticsNameAttr = (clickEventData, gaNameAttrValue) => {
  let gaNameAttr = '';
  if (isHamburgerMenuOpened(clickEventData) || isHamburgerMenuClosed(clickEventData)) {
    gaNameAttr = isHamburgerMenuOpened(clickEventData) ? GA_HAMBURGER_MENU_NAMES_TYPES.open : GA_HAMBURGER_MENU_NAMES_TYPES.close;
  } else {
    gaNameAttr = gaNameAttrValue;
  }
  return gaNameAttr;
};

export const matchObjectKeyAndUpdateItsValue = (eventData) => {
  const eventTargetGaData = eventData?.currentTarget?.dataset?.gaData;
  const decodedGaData = getParsedJsonData(eventTargetGaData);

  decodedGaData && Object.keys(decodedGaData).map((key) => {
    if (key.includes(GA_SEARCHED_ATTR_ACTION)) {
      decodedGaData[key] = setGoogleAnalyticsActionAttr(decodedGaData[key]);
    }
    if (key.includes(GA_SEARCHED_ATTR_NAME)) {
      decodedGaData[key] = setGoogleAnalyticsNameAttr(eventData, decodedGaData[key]);
    }
  });

  return decodedGaData;
};

const addGaEventListeners = () => {
  const gaSelectorsList = Array.from(document.querySelectorAll(GOOGLE_ANALYTICS_DATA_ATTR));

  const windowDataLayer = window.dataLayer || [];

  const gaDataLayerPush = (gaDataSet) => windowDataLayer.push(
    gaDataSet
  );

  return gaSelectorsList.map((gaSelector) => {
    gaSelector.addEventListener('click', (e) => {
      const updatedDecodedGaData = matchObjectKeyAndUpdateItsValue(e);
      return gaDataLayerPush(updatedDecodedGaData);
    });
  });
};

export { addGaEventListeners, getParsedJsonData };
