export default function LabelOverflowSetup() {
  // Put 'ontouchmove' attr to 'html' node to enable 'hover'
  // state of 'div' element if user tap on that element.

  // The change have to be added since the 'hover' state is not
  // applying to 'div' elements in Safari browser on iOS devices.

  const userAgent = window.navigator.userAgent;

  if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
    const html = document.querySelector('html');
    html.setAttribute('ontouchmove', '');
  }
}
