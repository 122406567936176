import './bundle.shared.css.js';

import '@shared-atoms/Icon/IconSet';
import FieldMask from '@shared-modifiers/FieldMask/FieldMask';
import FormValidation from '@shared-modifiers/FormValidation/FormValidation';
import Modal from '@shared-modifiers/Modal/Modal';

import { addGaEventListeners } from '../lib/googleAnalytics';

import Header from './elements/modifiers/header/header';
import Dropdown from './elements/modifiers/dropdown/dropdown';
import PasswordChecker from './elements/modifiers/passwordChecker/passwordChecker';
import LabelOverflow from './elements/modifiers/LabelOverflow/LabelOverflow';
import DynamicProviderFilter from './elements/modifiers/DynamicProviderFilter/DynamicProviderFilter';
import FixedSidebar from './elements/modifiers/FixedSidebar/FixedSidebar';
import ExpandableTab from './elements/modifiers/ExpandableTab/ExpandableTab';

function componentSetup() {
  const elInputs = document.querySelectorAll('.js-input');
  elInputs.forEach((input) => FieldMask({ el: input }));

  Header();
  Modal();
  Dropdown();
  FormValidation();
  PasswordChecker();
  LabelOverflow();
  ExpandableTab();
  DynamicProviderFilter();
}

document.addEventListener('DOMContentLoaded', () => {
  componentSetup();
  addGaEventListeners();
  FixedSidebar();
});
