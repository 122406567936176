const hasCookieConsent = () => {
  if (!window.OnetrustActiveGroups) return true;
  return window.OnetrustActiveGroups?.includes('C0003');
};

const setCookie = (cookieName, cookieValue, daysToExpire) => {
  if (!hasCookieConsent()) return;

  const date = new Date();
  date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
  document.cookie = `${cookieName}=${cookieValue}; expires=${date.toGMTString()}`;
  return document.cookie;
};

const getCookie = (cookieName) => {
  const cookie = {};

  document.cookie.split(';').map((el) => {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
    return el;
  });

  return cookie[cookieName];
};

export { setCookie, getCookie };
