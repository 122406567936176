import debounce from 'lodash.debounce';
import { GA_CSS_CLASS_MENU_OPENED, GA_CSS_CLASS_MENU_CLOSED } from '../../../../lib/googleAnalytics';

export default function header() {
  const elBody = document.body;
  const elHeader = elBody.querySelector('.js-header');
  const elPageContainer = elBody.querySelector('.js-page-container');
  const elHamburgerIcon = elBody.querySelector('.js-hamburger-button');
  const elHamburgerMenu = elBody.querySelector('.js-hamburger-menu');

  const iconOpenIconClass = 'c-header--open-menu';
  const pageContainerFixedClass = 'o-page-container--fixed';
  const bodyOverflowClass = 'body-overflow';
  const hamburgerMenuOpenClass = 'o-hamburger-menu--open';

  let menuIsOpen = false;

  function setMenuMarginTop() {
    elHamburgerMenu.style.top = `${elHeader.clientHeight
      + elHeader.getBoundingClientRect().top - 2}px`;
    elHamburgerMenu.style.maxHeight = `calc(100vh - ${elHamburgerMenu.style.top}`;
  }

  function addGoogleAnalyticsCssClass(callbackFunction) {
    return callbackFunction();
  }

  function toggleMenu(e) {
    e.stopPropagation();
    if (e.target.closest('.o-hamburger-menu__menu')) return;
    if (!menuIsOpen) {
      elPageContainer.classList.add(pageContainerFixedClass);
      setMenuMarginTop();
      elHeader.classList.add(iconOpenIconClass);
      elHamburgerMenu.classList.add(hamburgerMenuOpenClass);
      elBody.classList.add(bodyOverflowClass);
      addGoogleAnalyticsCssClass(() => elHamburgerIcon.classList.remove(GA_CSS_CLASS_MENU_CLOSED));
      addGoogleAnalyticsCssClass(() => elHamburgerIcon.classList.add(GA_CSS_CLASS_MENU_OPENED));
    } else {
      elPageContainer.classList.remove(pageContainerFixedClass);
      elHeader.classList.remove(iconOpenIconClass);
      elHamburgerMenu.classList.remove(hamburgerMenuOpenClass);
      elBody.classList.remove(bodyOverflowClass);
      addGoogleAnalyticsCssClass(() => elHamburgerIcon.classList.remove(GA_CSS_CLASS_MENU_OPENED));
      addGoogleAnalyticsCssClass(() => elHamburgerIcon.classList.add(GA_CSS_CLASS_MENU_CLOSED));
    }
    menuIsOpen = !menuIsOpen;
  }

  function menuBlurEvent(e) {
    e.preventDefault();
    if (e.type === 'click') return;
    if (!menuIsOpen) return;

    const leavingParent = !elHamburgerIcon.contains(e.relatedTarget);
    if (leavingParent) {
      elHamburgerIcon.focus();
    }
  }

  function init() {
    elHamburgerIcon.addEventListener('click', toggleMenu);
    elHamburgerIcon.addEventListener('focusout', menuBlurEvent);
    window.addEventListener(
      'resize',
      debounce(
        () => setMenuMarginTop(),
        500
      )
    );
  }

  if (elHamburgerIcon) init();
}
