const requireAll = (context) => context.keys().map(context);

// import variables before components
require('./elements/variables/index.css');
require('./elements/atoms/Root/sharedRoot.css');

// require the rest of the components
requireAll(require.context('./elements/atoms/', true, /\.css$/));
requireAll(require.context('./elements/molecules/', true, /\.css$/));
requireAll(require.context('./elements/organisms/', true, /\.css$/));
requireAll(require.context('./elements/modifiers/', true, /\.css$/));
