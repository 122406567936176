import validations, {
  ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN,
  ALPHANUMERIC_PATTERN,
  FORMS_VALIDATION_FIELDS_IDS,
  prepareRegexForHtmlPattern
} from '@shared-modifiers/FormValidation/validationRules';

export default function FormValidation() {
  const elForms = document.querySelectorAll('.js-form');

  const hiddenClass = 'u-hidden';
  const alertClass = 'js-error';

  const {
    VALIDATION_FIELD_USERNAME,
    VALIDATION_FIELD_FIRSTNAME,
    VALIDATION_FIELD_SURNAME,
    VALIDATION_FIELD_ADRESS,
    VALIDATION_FIELD_CITY,
    VALIDATION_FIELD_EMAIL,
    VALIDATION_FIELD_SECURITY_QUESTION
  } = FORMS_VALIDATION_FIELDS_IDS;

  const LIST_OF_TRIMED_FIELDS = [
    VALIDATION_FIELD_USERNAME,
    VALIDATION_FIELD_FIRSTNAME,
    VALIDATION_FIELD_SURNAME,
    VALIDATION_FIELD_ADRESS,
    VALIDATION_FIELD_CITY,
    VALIDATION_FIELD_EMAIL,
    VALIDATION_FIELD_SECURITY_QUESTION
  ];

  let debounceTimerTrim = null;
  let debounceTimer = null;

  const DEFAULT_VALIDATION_MESSAGE = 'Please enter the value in a valid format';

  const c_actions = {
    addClass(el, classState) {
      el.classList.add(classState);
    },
    removeClass(el, classState) {
      el.classList.remove(classState);
    }
  };

  function initListeners(elForm) {
    const elInputs = elForm.querySelectorAll('input');
    const elButton = elForm.querySelector('.js-submit');
    const cardRedClass = elForm.classList.contains('modal__container') ? 'modal__container--error' : 'o-form-card--error';
    const checkboxRequiredList = Array.from(elForm.querySelectorAll('.js--at-least-one-checkbox-required'));

    function setFormErrors() {
      const containerError = elForm.querySelector(`.${alertClass}`);
      const invalidElements = elForm.querySelectorAll(':invalid');
      const numberOfErrors = invalidElements.length;

      // Check if there are any errors
      if (!numberOfErrors) {
        c_actions.removeClass(elForm, cardRedClass);
        c_actions.addClass(containerError, hiddenClass);
        elButton.removeAttribute('disabled');
        return;
      }

      // Add form error class
      !elForm.classList.contains(cardRedClass) && c_actions.addClass(elForm, cardRedClass);

      // There is a single error - display alert
      if (numberOfErrors === 1) {
        const alertMessage = invalidElements[0].dataset.customError ? invalidElements[0].dataset.customError : `${invalidElements[0].name} is invalid`;
        c_actions.removeClass(containerError, hiddenClass);
        containerError.textContent = alertMessage;
        return;
      }

      c_actions.addClass(containerError, hiddenClass);

      if (elForm.dataset.disableButton) elButton.setAttribute('disabled', true);
    }

    const getErrorMessageForHtmlPattern = (inputValidationsPattern) => {
      const dedicatedMessagesForPattern = {
        [prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN)]: 'You entered character that is not allowed',
        [ALPHANUMERIC_PATTERN]: 'Please enter alphanumeric value'
      };

      return dedicatedMessagesForPattern[inputValidationsPattern] ? dedicatedMessagesForPattern[inputValidationsPattern] : DEFAULT_VALIDATION_MESSAGE;
    };

    function getMessage(input) {
      const status = input.validity;
      const maxLength = input.getAttribute('maxlength');
      const minLength = input.getAttribute('minlength');

      const inputValidationsPattern = validations[input?.dataset?.validation]?.pattern;

      if (status.valueMissing) return 'This field is required';
      if (input.dataset.validationTarget) {
        const targetInput = document.querySelector(`#${input.dataset.validationTarget}`);
        if (targetInput && targetInput.checkValidity()) return input.dataset.customError;
      }

      if (minLength && input.value.length < minLength) return `This field must be at least ${minLength} characters long`;
      if (maxLength && input.value.length > maxLength) return `This field must be at most ${maxLength} characters long`;
      if (status.patternMismatch) return getErrorMessageForHtmlPattern(inputValidationsPattern);
      return DEFAULT_VALIDATION_MESSAGE;
    }

    function checkCheckboxRequiredList(checkboxList) {
      if (checkboxList) {
        const atLeastOneCheckboxIsChecked = checkboxList
          .reduce((acc, curr) => acc || curr.querySelector('.o-checkbox__input').checked, false);
        return checkboxList.map((label) => {
          const input = label.querySelector('.o-checkbox__input');
          input.required = !atLeastOneCheckboxIsChecked;
          return label;
        });
      }
      return false;
    }

    function setButtonState() {
      if (!elButton) return;
      if (elForm.dataset.disableButton) {
        checkboxRequiredList && checkCheckboxRequiredList(checkboxRequiredList);

        const invalidElements = elForm.querySelectorAll(':invalid, .o-dropdown-input--error, .o-input-text--error');

        if (invalidElements.length > 0) elButton.setAttribute('disabled', true);
        else elButton.removeAttribute('disabled');
      }
    }

    function getSetAlert(parent, isAddingClass, message, curState) {
      const elErrorMessage = parent.querySelector('.o-input-text__error');
      if (elErrorMessage) {
        const classAction = isAddingClass ? c_actions.addClass : c_actions.removeClass;

        elErrorMessage.textContent = message;
        classAction(parent, curState);
      }

      setButtonState();
    }

    function findFieldsetParent(targetInput) {
      const parent = targetInput.closest('.o-input-text') || targetInput.closest('.o-dropdown-input');
      if (parent) return parent;
      return null;
    }

    function crossedFieldsValidity(targetInput, input) {
      const areBothValuesEqual = targetInput.value === input.value;

      const inputCustomValidity = areBothValuesEqual
        ? ''
        : "The value doesn't match with the target field value";

      input.setCustomValidity(inputCustomValidity);

      return !areBothValuesEqual;
    }

    function checkInputValidity(input) {
      // START * Custom check for password and email confirmation fields *

      if (input.dataset.validationTarget) {
        const targetInput = document.querySelector(`#${input.dataset.validationTarget}`);

        if (targetInput) {
          targetInput.dataset.requireConfirmFieldValidation = input.id;

          if (targetInput.checkValidity()) {
            return crossedFieldsValidity(targetInput, input);
          }
        }
      }
      if (input.dataset.requireConfirmFieldValidation) {
        const confirmField = document.querySelector(`#${input.dataset.requireConfirmFieldValidation}`);
        if (confirmField) {
          if (input.checkValidity() && confirmField.value.length) {
            validateFormElements([confirmField]);
          }
        }
      }
      // END * Custom check for password and email confirmation fields *

      // Default check for general cases
      return !input.checkValidity();
    }

    function checkValidity(input, parent) {
      const errorClass = input.classList.contains('js-dropdown__input') ? 'o-dropdown-input--error' : 'o-input-text--error';
      getSetAlert(parent, checkInputValidity(input), getMessage(input), errorClass);
      setButtonState();
    }

    function validateFormElements(elements) {
      Array.from(elements)
        .filter((element) => !element.disabled)
        .map((element) => {
          element.setCustomValidity('');
          return { targetInput: element, inputParent: findFieldsetParent(element) };
        })
        .filter((obj) => obj.inputParent)
        .forEach((obj) => checkValidity(obj.targetInput, obj.inputParent));
    }

    function changeEventHandler(e) {
      clearTimeout(debounceTimer);
      if (e.target.tagName === 'INPUT') {
        if (['radio', 'checkbox'].includes(e.target.type)) {
          setButtonState();
          return;
        }

        validateFormElements([e.target]);
      }
    }

    function trimFormFields(e) {
      const target = e?.target;
      const targetType = target?.type;

      const isTrimAllowed = (target && LIST_OF_TRIMED_FIELDS.includes(target?.dataset?.validation));

      const isAllowedTypesOfInputs = (targetType === 'text' || targetType === 'password' || targetType === 'email');

      if (isTrimAllowed && isAllowedTypesOfInputs) {
        clearTimeout(debounceTimerTrim);
        debounceTimerTrim = setTimeout(() => {
          e.target.value = e.target.value.trim();
        }, 2000);
      }
    }

    function submitEventHandler(e) {
      validateFormElements(e.target.elements);
      if (!e.target.checkValidity()) {
        e.preventDefault();
        setFormErrors();
      } else {
        elButton.disabled = true;
        elButton.innerHTML = 'Please wait';
      }
    }

    function keydownHandler(e) {
      trimFormFields(e);
      if (e.target.nodeName === 'INPUT') {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          validateFormElements([e.target]);
        }, 400);
      }

      if (e.key === 'Tab') {
        setButtonState();
      }
    }

    function addValidation(input) {
      if (!input.dataset.validation) return;
      const inputValidations = validations[input.dataset.validation];
      if (!inputValidations) {
        console.error(Error(`Validation rules for ${input.dataset.validation} have not been found`));
        return;
      }
      if (inputValidations.min) input.setAttribute('minlength', inputValidations.min);
      if (inputValidations.max) input.setAttribute('maxlength', inputValidations.max);
      if (inputValidations.pattern) input.setAttribute('pattern', inputValidations.pattern);
      if (inputValidations.required) input.setAttribute('required', inputValidations.required);
    }

    elForm.setAttribute('novalidate', true);

    elInputs.forEach((input) => {
      addValidation(input);
    });

    elForm.addEventListener('change', changeEventHandler);
    elForm.addEventListener('keydown', keydownHandler);
    elForm.addEventListener('submit', submitEventHandler);

    // the first check of state of the the send button in case
    // the state of radio buttons is cached in the form
    // by the browser if the user returns to the page
    const firstCheckOfButtonState = setInterval(() => {
      if (document.readyState === 'complete') {
        setButtonState();
        clearInterval(firstCheckOfButtonState);
      }
    }, 100);
  }

  function init() {
    elForms.forEach((form) => {
      initListeners(form);
    });
  }
  init();
}
