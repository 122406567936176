const dropdown = () => {
  const dropdownElementClass = 'js-dropdown';
  const dropdownElements = document.querySelectorAll(`.${dropdownElementClass}`);

  if (!dropdownElements) return;

  const dropdownInputClass = 'js-dropdown__input';
  const dropdownTextClass = 'js-dropdown__value';
  const dropdownOptionClass = 'js-dropdown__option';
  const jsDropdownActive = 'js-dropdown--active';
  const jsDropdownSelected = 'js-dropdown--selected';
  const cssDropdownActive = 'o-dropdown-input--active';
  const cssDropdownSelected = 'o-dropdown-input__option--selected';
  const cssDropdownDisabled = 'o-dropdown-input__element--disabled';

  const enterKeyCode = 13;
  const spaceKeyCode = 32;
  const arrowUpKeyCode = 38;
  const arrowDownKeyCode = 40;
  const tabKeyCode = 9;
  const shiftKeyCode = 16;
  const latinAlphabetKeyCodes = [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90];

  const arrSelectKeyCodes = [enterKeyCode, spaceKeyCode];
  const arrNavigatKeyCodes = [arrowUpKeyCode, arrowDownKeyCode];
  const arrControlKeycodes = [tabKeyCode, shiftKeyCode];
  const arrTargetKeyCodes = [...arrSelectKeyCodes, ...arrNavigatKeyCodes, ...arrControlKeycodes, ...latinAlphabetKeyCodes];

  const updateDropdownSelectedValueAriaLabel = (currentDropdown, currentDropdownAriaLabel, ariaLabelAboutSelectedOption) => currentDropdown.setAttribute('aria-label',
    currentDropdownAriaLabel.substring(0, currentDropdownAriaLabel.indexOf(','))
           + ariaLabelAboutSelectedOption);

  const setDropdownSelectedValueAriaLabel = (currentDropdown, currentDropdownAriaLabel, ariaLabelAboutSelectedOption) => currentDropdown.setAttribute('aria-label', currentDropdownAriaLabel + ariaLabelAboutSelectedOption);

  function initListeners(dropdownElement) {
    if (dropdownElement.classList.contains(cssDropdownDisabled)) return;

    const firstOption = dropdownElement.querySelector(`.${dropdownOptionClass}`);
    const dropdownInput = dropdownElement.querySelector(`.${dropdownInputClass}`);
    const dropdownText = dropdownElement.querySelector(`.${dropdownTextClass}`);
    const selectedValue = {};
    var isActive = false;

    function hideDropdown() {
      dropdownElement.classList.remove(jsDropdownActive, cssDropdownActive);
      dropdownElement.setAttribute('aria-expanded', 'false');
      isActive = false;
    }

    function showDropdown() {
      dropdownElement.classList.add(jsDropdownActive, cssDropdownActive);
      dropdownElement.setAttribute('aria-expanded', 'true');
      isActive = true;
    }

    function toggleDropdown() {
      isActive ? hideDropdown() : showDropdown();
    }

    function focusFirstOption() {
      toggleDropdown();
      firstOption.focus(dropdownElement);
    }

    function setDropdownActions(initTarget) {
      var currentTarget = initTarget;

      function updateCurrentTarget(target) {
        currentTarget = target;
      }

      function setTextAndValue() {
        dropdownInput.value = currentTarget.dataset.value;
        dropdownText.textContent = currentTarget.innerText;
        // eslint-disable-next-line no-undef
        dropdownInput.dispatchEvent(new Event('change', { bubbles: true }));
      }

      function toggleUpdateSelect() {
        const currentSelected = selectedValue[dropdownInput.name];

        const currentDropdown = currentTarget.closest(`.${dropdownElementClass}`);
        const options = Array.from(currentDropdown.querySelectorAll(`.${dropdownOptionClass}`));

        /* Remove Dropdown default value bolding  */
        options.map((option) => {
          option.classList.remove(cssDropdownSelected);
          return option;
        });

        if (currentSelected) {
          currentSelected.classList.remove(jsDropdownSelected, cssDropdownSelected);
          currentSelected.ariaSelected = false;
        }
        currentTarget.classList.add(jsDropdownSelected, cssDropdownSelected);
        currentTarget.ariaSelected = true;
        selectedValue[dropdownInput.name] = currentTarget;

        let currentDropdownAriaLabel = currentDropdown.getAttribute('aria-label');
        const ariaLabelAboutSelectedOption = `, selected value: ${currentTarget.innerText} in`;

        if (!currentDropdownAriaLabel) {
          currentDropdownAriaLabel = '';
        }

        setDropdownSelectedValueAriaLabel(currentDropdown, currentDropdownAriaLabel, ariaLabelAboutSelectedOption);
        updateDropdownSelectedValueAriaLabel(currentDropdown, currentDropdownAriaLabel, ariaLabelAboutSelectedOption);
      }

      function sharedSelectFunction() {
        toggleUpdateSelect();
        setTextAndValue();
        dropdownElement.focus();
        toggleDropdown();
      }

      function isDropdownOption() {
        return currentTarget.dataset.value;
      }

      function focusPreviousOption() {
        if (currentTarget?.previousElementSibling) currentTarget.previousElementSibling?.focus();
      }

      function focusNextOption() {
        if (currentTarget?.nextElementSibling) currentTarget.nextElementSibling?.focus();
      }

      function keydownFunction() {
        isDropdownOption() ? focusNextOption() : focusFirstOption();
      }

      function keySelectFunction() {
        isDropdownOption() ? sharedSelectFunction(currentTarget) : focusFirstOption();
      }

      function preselectDropdownOption(eventKey) {
        const typedChar = String.fromCharCode(eventKey).toLowerCase();
        const options = dropdownElement.querySelectorAll(`.${dropdownOptionClass}`);
        const matchingOption = Array.from(options).find((option) => option.innerText.toLowerCase().startsWith(typedChar));
        if (matchingOption) {
          matchingOption.focus();
        }
        return matchingOption;
      }

      function keyCommand(eventKey) {
        switch (eventKey) {
          case enterKeyCode:
          case spaceKeyCode:
            keySelectFunction();
            break;
          case arrowUpKeyCode:
            focusPreviousOption();
            break;
          case arrowDownKeyCode:
            keydownFunction();
            break;
          default:
            preselectDropdownOption(eventKey);
            break;
        }
      }

      function clickFunction() {
        isDropdownOption() ? sharedSelectFunction(currentTarget) : toggleDropdown();
      }

      return {
        updateCurrentTarget,
        key: keyCommand,
        click: clickFunction
      };
    }

    const dropdownActions = setDropdownActions(dropdownElement);

    function dropdownKeydownHandler(e) {
      const eventKey = e.keyCode;

      if (!arrTargetKeyCodes.includes(eventKey)) {
        hideDropdown();
        return;
      }

      dropdownActions.updateCurrentTarget(e.target);

      if (!arrControlKeycodes.includes(eventKey)) {
        e.preventDefault();
        dropdownActions.key(eventKey);
      }
    }

    function dropdownClickHandler(e) {
      dropdownActions.updateCurrentTarget(e.target);
      dropdownActions.click();
    }

    function dropdownFocusoutHandler(e) {
      const leavingParent = !dropdownElement.contains(e.relatedTarget);
      if (leavingParent) hideDropdown();
    }

    dropdownElement.addEventListener('keydown', dropdownKeydownHandler);
    dropdownElement.addEventListener('click', dropdownClickHandler);
    dropdownElement.addEventListener('focusout', dropdownFocusoutHandler);
  }
  /*
  Edge case - when the "back" button is clicked on forms,
   the backend sets the preselected (by user) value in dropdownd.
   We need to add accessibility and bolding functionalities to the selected option.
 */
  function onLoadPreselectDefaultDropdownValue(dropdownElement) {
    const selectedOption = dropdownElement.querySelector('.o-dropdown-input__value');
    const selectedValue = selectedOption.textContent;
    const options = Array.from(dropdownElement.querySelectorAll(`.${dropdownOptionClass}`));

    const currentDropdown = dropdownElement.closest(`.${dropdownElementClass}`);
    let currentDropdownAriaLabel = currentDropdown.getAttribute('aria-label');

    if (!currentDropdownAriaLabel) {
      currentDropdownAriaLabel = '';
    }

    const ariaLabelAboutSelectedOption = `, selected value: ${selectedValue} in`;

    updateDropdownSelectedValueAriaLabel(currentDropdown, currentDropdownAriaLabel, ariaLabelAboutSelectedOption);
    setDropdownSelectedValueAriaLabel(currentDropdown, currentDropdownAriaLabel, ariaLabelAboutSelectedOption);
    
    return options.map((option) => {
      if (option.textContent === selectedValue) {
        option.classList.add(cssDropdownSelected);
      }
      return option;
    });
  }

  function dropdownInit() {
    dropdownElements.forEach((dropdownElement) => {
      onLoadPreselectDefaultDropdownValue(dropdownElement);
      initListeners(dropdownElement);
    });
  }

  dropdownInit();
};

module.exports = dropdown;
