export default function TabButtonNav() {
  const elTabButtonNavs = document.querySelectorAll('.js-expandable-tab');
  if (!elTabButtonNavs) return;

  const elTabButtons = document.querySelectorAll('.js-tab-button');
  const elTabContents = document.querySelectorAll('.js-tab-content');

  elTabButtons.forEach((elButton) => {
    function initTabButtonTarget(elTabContent) {
      function tabButtonHandler(e) {
        var elButtonCurrentlyActive = elButton.classList.contains('expandable-tab__button--active');
        var elCurrentContent = elTabContent;
        var elCurrentExpandableTab = e.currentTarget.closest('.expandable-tab');

        e.preventDefault();
        elTabContents.forEach((elContent) => {
          if (elCurrentExpandableTab.contains(elContent)) {
            elContent.classList.remove('expandable-tab__content--active');
          }
        });

        elTabButtons.forEach((elBtn) => {
          if (elCurrentExpandableTab.contains(elBtn)) {
            elBtn.classList.remove('expandable-tab__button--active');
          }
        });

        if (elButtonCurrentlyActive) {
          elButton.classList.remove('expandable-tab__button--active');
          elCurrentContent.classList.remove('expandable-tab__content--active');
        } else {
          elButton.classList.add('expandable-tab__button--active');
          elCurrentContent.classList.add('expandable-tab__content--active');
        }
      }

      elButton.addEventListener('click', tabButtonHandler);
    }

    if (elButton.hasAttribute('data-tab')) {
      const tabTarget = elButton.getAttribute('data-tab');
      const elTabTarget = document.querySelector(`[data-tab-target="${tabTarget}"]`);
      elTabTarget && initTabButtonTarget(elTabTarget);
    }
  });
}
