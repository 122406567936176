import MicroModal from 'micromodal';
import { setCookie, getCookie } from '@shared-modifiers/Cookie/Cookie';


export default function modalSetup() {
  const modalsConfig = {
    openTrigger: 'data-modal-open',
    closeTrigger: 'data-modal-close',
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    debugMode: false,
    onShow: (modal) => {
      // find '.js-modal-focus' el to focus only on desktop
      // if haven't found then set focus on dialog window
      if (!window.matchMedia('(max-width: 767px)').matches) {
        const modalEl = document.querySelector(`#${modal.id}`);
        const dialogEL = modalEl.querySelector('.js-form');
        const focusTarget = modalEl.querySelector('.js-modal-focus');
        const cssAnimationDuration = 300;

        if (focusTarget) {
          focusTarget.focus();
        } else {
          dialogEL.tabIndex = 0;

          // set a focus only after the end of the animation
          setTimeout(() => {
            dialogEL.focus();
          }, cssAnimationDuration);
        }
      }
    },
    onClose: (modal) => {
      if (modal.classList.contains('js-modal--auto-open')) {
        setCookie(modal.id, 'wasDisplayed', 365);
      }
    }

  };

  MicroModal.init(modalsConfig);

  const automaticallyOpeningModal = document.querySelector('.js-modal--auto-open');
  if (automaticallyOpeningModal) {
    const automaticallyOpeningModalID = automaticallyOpeningModal.id;
    const delayBeforeOpeningModal = 2000;

    if (!getCookie(automaticallyOpeningModalID)) {
      setTimeout(() => {
        MicroModal.show(automaticallyOpeningModal.id, modalsConfig);
      }, delayBeforeOpeningModal);
    }

    const links = Array.from(automaticallyOpeningModal.querySelectorAll('a'));
    if (links) {
      links.map((link) => {
        link.addEventListener('click', (e) => {
          if (!getCookie(automaticallyOpeningModalID)) {
            e.preventDefault();
            setCookie(automaticallyOpeningModalID, 'wasDisplayed', 365);
            e.target.click();
          }
        });
        return link;
      });
    }
  }
}
