export default function DynamicProviderFilter() {
  const elFilterButtons = document.querySelectorAll('.js-filter__button');
  if (!elFilterButtons) return;

  const elFilterContents = document.querySelectorAll('.js-filter__content');
  const filterController = {
    all: {
      letter: 'all'
    }
  };

  elFilterContents.forEach((elContent) => {
    const contentLetter = elContent.dataset.contentLetter?.toLowerCase();
    if (!contentLetter) return;
    filterController[contentLetter] = {
      content: elContent
    };
  });

  elFilterButtons.forEach((elFilterBtn) => {
    const filterLetter = elFilterBtn.dataset.filterLetter?.toLowerCase();
    if (!filterLetter) return;
    if (filterController[filterLetter]) {
      filterController[filterLetter].button = {
        filterButton: elFilterBtn,
      };

      elFilterBtn.addEventListener('click', () => {
        var curLetter = filterLetter;

        if (curLetter === 'all') {
          elFilterContents.forEach((elContent) => {
            elContent.classList.remove('visually-hidden');
            elContent.setAttribute('aria-expanded', true);
          });
          return;
        }

        if (filterController[curLetter].content) {
          elFilterContents.forEach((elContent) => {
            elContent.classList.add('visually-hidden');
            elContent.setAttribute('aria-expanded', false);
          });

          filterController[curLetter].content.classList.remove('visually-hidden');
          filterController[curLetter].content.setAttribute('aria-expanded', true);
        }
      });
    }
  });
}
