function FixedSidebar() {
  var elSidebar = document.querySelector('.js-sidebar');
  if (!elSidebar) return;

  function sidebarClickHandler(e) {
    var parentSidebar = e.target.closest('.js-sidebar');
    if (parentSidebar.classList.contains('is-closed')) {
      parentSidebar.classList.remove('is-closed');
    } else {
      parentSidebar.classList.add('is-closed');
    }
  }

  function init() {
    var btns = document.querySelectorAll('.js-sidebar--toggle');
    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', sidebarClickHandler);
    }
  }

  init();
}

module.exports = FixedSidebar;
